<template>
  <div>
    <b-card :id="'collaborateur-card' + index" no-body class="card-revenue-budget text-dark" bg-variant="gradient-secondary" style="background-image: linear-gradient(45deg, #ffffff, #ffffff00)">
      
      
      <b-row class="mx-0">
        <b-col :md="can('Update user') || can('Add training user') || can('management permissions user') || can('Add document user') ? '9' : '12'" class="revenue-report-wrapper" :class="{ 'collaborateur-card' : !collaborateur.isActive }">
          <b-row>
            <b-col cols="12" class="mb-2">
              <span class="h3 font-weight-bolder">{{ collaborateur.prenomCollaborateur + ' ' + collaborateur.nomCollaborateur }}</span>     <span class="text-muted">{{ collaborateur.statut_juridique }}</span>
              <b-badge v-if="collaborateur.declarant_tracfin" class="ml-1" :variant="sourceVariant('DECLARANTTRACFIN')">Déclarant TRACFIN</b-badge>
              <b-badge v-if="collaborateur.dpo" class="ml-1" :variant="sourceVariant('DPO')">DPO</b-badge>
              <b-badge v-if="collaborateur.correspondant_tracfin" class="ml-1" :variant="sourceVariant('CORRESPONDANTTRACFIN')">Correspondant TRACFIN</b-badge>
              <div>
                <b-badge v-if="collaborateur.isPrincipal" :variant="sourceVariant('ADMINISTRATOR')">Admin</b-badge>
                <b-badge v-else-if="!collaborateur.isPrincipal && collaborateur.isActive && !collaborateur.isNonUtilisateur" :variant="sourceVariant('UTILISATEURBUBBLEIN')" class="mr-1">Utilisateur</b-badge>
                <b-badge v-else-if="collaborateur.isNonUtilisateur" :variant="sourceVariant('NONUTILISATEURBUBBLEIN')" class="mr-1">Non utilisateur</b-badge>
                <b-badge v-if="!collaborateur.isPrincipal && !collaborateur.isActive && !collaborateur.isNonUtilisateur" :variant="sourceVariant('INACTIF')">Inactif</b-badge>
              </div>
              <div  class="mt-1">
                <small class="text-muted d-block" v-if="collaborateur.hasOwnProperty('fonction')">{{ collaborateur.fonction }}</small>
                <small class="text-muted d-block">
                  <span v-if="collaborateur.hasOwnProperty('indicatif_tel') && collaborateur.indicatif_tel && collaborateur.hasOwnProperty('tel') && collaborateur.tel">({{ collaborateur.indicatif_tel }})</span>
                  <span v-if="collaborateur.hasOwnProperty('tel') && collaborateur.tel">
                    <span v-if="collaborateur.hasOwnProperty('indicatif_tel') && collaborateur.indicatif_tel">{{ collaborateur.tel.substring(1) }}</span>
                    <span v-else>{{ collaborateur.tel }}</span>
                  </span>
                </small>
                <small class="text-muted d-block" v-if="collaborateur.hasOwnProperty('email')">{{ collaborateur.email }}</small>
              </div>
            </b-col>

            <!-- BUB-289 Pour la V1, masquer les boutons Oui/Non concernant le CV, Honorabilité et Formation sur les fiches collaborateurs -->
            <!--<b-col cols="12">
              <p>Niveau de qualification: {{ collaborateur.niveau }}</p>
            </b-col>
            <b-col md="4" sm="12">
              <b-form-group label="Curriculum Vitae" label-class="p-0">
                <b-form-radio-group v-model="collaborateur.curriculumVitae" :options="optionsCheckBox" disabled class="demo-inline-spacing" :name="'curriculum-vitae-' + index" />
              </b-form-group>
            </b-col>
            <b-col md="4" sm="12">
              <b-form-group label="Justificatif de formation" label-class="p-0">
                <b-form-radio-group v-model="collaborateur.justificatifFormation" :options="optionsCheckBox" disabled class="demo-inline-spacing" :name="'justificatif-formation' + index" />
              </b-form-group>
            </b-col>
            <b-col md="4" sm="12">
              <b-form-group label="Attestation d'honorabilité" label-class="p-0">
                <b-form-radio-group v-model="collaborateur.attestationHonorabilite" :options="optionsCheckBox" disabled class="demo-inline-spacing" :name="'attestation-honorabilite' + index" />
              </b-form-group>
            </b-col> -->

            <!-- <b-col cols="12">
              <b-row>
                <b-col md="4" sm="12" v-for="(type, index) in collaborateur.typesStatesUser" :key="index">
                  <div v-if="type.nameType==='Attestation de formation DDA'" class="d-inline">
                    <span :class="type.isExistType && checkDDA(collaborateur.nbHrsOfFormationCollaborateur) >=15  ? 'bullet-success' : 'bullet-danger'" class="bullet bullet-sm mr-1"/>
                    <span> {{ type.nameType }} <span v-if="type.isExistType">{{ collaborateur.nbHrsOfFormationCollaborateur ? displayNbrHeures(collaborateur.nbHrsOfFormationCollaborateur) : ''}}</span> </span>
                  </div>
                  <div v-else class="d-inline">
                    <span :class="type.isExistType ? 'bullet-success' : 'bullet-danger'" class="bullet bullet-sm mr-1"/>
                    <span> {{ type.nameType }} </span>
                  </div>
                </b-col>
              </b-row>
            </b-col> -->
            <b-col cols="12">
              <app-collapse accordion type="margin">
                <app-collapse-item :title="titleWithStyle" v-if="can('List trainings user')" v-show="false">
                  <b-overlay :show="showLoading" no-wrap />
                  <vue-good-table
                    :columns="formationColumns"
                    :rows="collaborateur.formation"
                    :rtl="direction"
                    style-class="vgt-table condensed"
                    :pagination-options="{
                      enabled: true,
                      mode: 'records',
                      perPage: pageLength,
                      rowsPerPageLabel: '',
                      dropdownAllowAll: false,
                      perPageDropdownEnabled: false,
                      nextLabel: 'Suivant',
                      prevLabel: 'Précédent',
                      ofLabel: 'de',
                      infoFn: params => ``
                    }"
                  >
                    <template slot="table-row" slot-scope="props">
                      <!-- Column: Action -->
                      <span v-if="props.column.field === 'actions'">
                        <span>
                          <b-dropdown variant="link" toggle-class="text-decoration-none p-0" no-caret>
                            <template v-slot:button-content>
                              <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                            </template>
                            <b-dropdown-item v-if="can('Visualize training user')" @click="displayDocument(props.row.idDocument, props.row.Name, props.row.NomDuDocument)">
                              <feather-icon icon="SearchIcon" class="mr-50" />
                              <span>Visualiser</span>
                            </b-dropdown-item>
                            <b-dropdown-item v-if="can('Update training user')" @click="$emit('update-formation', collaborateur, props.row)">
                              <feather-icon icon="EditIcon" class="mr-50" />
                              <span>Éditer</span>
                            </b-dropdown-item>
                            <b-dropdown-item v-if="can('Delete training user')" @click="deleteFormation(props.row.formationId, props.row.vgt_id)">
                              <feather-icon icon="TrashIcon" class="mr-50" />
                              <span>Supprimer</span>
                            </b-dropdown-item>
                          </b-dropdown>
                        </span>
                      </span>
                    </template>
                    <!-- pagination -->
                    <template slot="pagination-bottom" slot-scope="props">
                      <b-row class="mt-2 align-items-center">
                        <b-col md="6" lg="5" xxl="3">
                          <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap ">
                              Affichage 1 à
                            </span>
                            <b-form-select
                              v-model="pageLength"
                              :options="['3','5','10']"
                              class="mx-1"
                              @input="(value)=>props.perPageChanged({currentPerPage:value})"
                            />
                            <span class="text-nowrap"> de {{ props.total }} entrées </span>
                          </div>
                        </b-col>
                    </b-row>
                      <b-row class="mt-2 align-items-center">
                        <b-col>
                          <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" @input="value => props.pageChanged({ currentPage: value })">
                            <template #prev-text>
                              <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                              <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                          </b-pagination>
                        </b-col>
                      </b-row>
                    </template>
                    <div slot="emptystate" class="text-center align-middle">
                      <span>Aucune donn&eacute;e disponible dans le tableau</span>
                    </div>
                  </vue-good-table>
                </app-collapse-item>
                <!-- <app-collapse-item :title="'Liste documents'" v-if="can('List documents user')">
                  <listing-document-by-courtier-and-user-vue 
                  @set-statut-document="deleteTypeDocument"
                  :document-user-data="collaborateur.documentUser"  />
                </app-collapse-item> -->
              </app-collapse>
            </b-col>
          </b-row>
        </b-col>

        <b-col v-if="can('Update user') || can('Add training user') || can('management permissions user') || can('Add document user')" md="3" class="budget-wrapper p-0" style="background-color: #4d25bc">
          <b-list-group flush>
            <b-list-group-item v-if="can('Update user')" active class="p-0" @click="$emit('update-collaborateur', collaborateur.userId, collaborateur.idQualification)">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="rounded-0 p-1 m-0 text-left" block variant="primary"> <feather-icon icon="EditIcon" size="16" /> Éditer profil </b-button>
            </b-list-group-item>

            <b-list-group-item v-show="false" v-if="can('Add training user')" active class="p-0" @click="$emit('formation-collaborateur', collaborateur)">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="rounded-0 p-1 m-0 text-left" block variant="primary"> <feather-icon icon="AwardIcon" size="16" /> Ajouter une formation </b-button>
            </b-list-group-item>
            <!-- && (collaborateur.userId !== userId && !collaborateur.isPrincipal)-->
            <b-list-group-item v-if="can('management permissions user') || isPrincipalCurrentUser === 1" active class="p-0" @click="$emit('select-user-for-permission', collaborateur)">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="rounded-0 p-1 m-0 text-left" block variant="primary"> <feather-icon icon="LockIcon" size="16" /> Gestion des droits </b-button>
            </b-list-group-item>

            <!-- <b-list-group-item v-if="can('Add document user')" active class="p-0" @click="$emit('create-document-collaborateur', collaborateur.userId)">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="rounded-0 p-1 m-0 text-left" block variant="primary"> <feather-icon icon="PaperclipIcon" size="16" /> Ajouter un document </b-button>
            </b-list-group-item> -->
          </b-list-group>
        </b-col>
      </b-row>
    </b-card>

    <b-modal id="modal-display-document-formation" ref="modal-display-document-formation" :title="document.name" cancel-title="Fermer" ok-title="Télécharger" cancel-variant="outline-secondary" @ok="downloadDocument(document.base64, document.nameToDownload)">
      <form ref="form">
        <b-img v-if="document.extensionDocument === 'IMAGE'" :src="document.base64" fluid :alt="document.name" />
      </form>
    </b-modal>
  </div>
</template>

<script>
import { BImg, BDropdown, BDropdownItem, BPagination, BFormSelect, BButton, BOverlay, BCard, BInputGroup, BFormInput, BInputGroupAppend, BCardBody, BBadge, BRow, BCol, BFormRadioGroup, BFormRadio, BFormGroup, BListGroup, BListGroupItem } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import ListingDocumentByCourtierAndUserVue from './components-actions-collaborateurs/document-collaborateur/ListingDocumentByCourtierAndUser.vue'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    VueGoodTable,
    // BSV
    BImg,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BPagination,
    BCard,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BCardBody,
    BOverlay,
    BFormRadio,
    BBadge,
    BRow,
    BCol,
    BFormRadioGroup,
    BFormGroup,
    BListGroup,
    BListGroupItem,
    // UI
    ListingDocumentByCourtierAndUserVue
  },
  directives: {
    Ripple
  },
  props: {
    courtierId: {
      type: Number,
      default: null,
      required: true
    },
    userId: {
      type: Number,
      default: null,
      required: true
    },
    isPrincipalCurrentUser: {
      type: Number,
      default: 0,
      required: true
    },
    index: {
      type: Number,
      default: null,
      required: true
    },
    collaborateur: {
      type: Object,
      default: null,
      required: true
    }
  },
  data() {
    return {
      titleWithStyle: null,
      document: {
        base64: null,
        name: null,
        nameToDownload: null,
        extensionDocument: null
      },
      showLoading: false,
      pageLength: 3,
      dir: false,
      selected: null,
      options: [
        { value: null, text: 'Action de masse' },
        { value: 'autre', text: ' --- ' }
      ],
      formationColumns: [
        { label: 'Date', field: 'date', type: 'date', dateInputFormat: 'dd/MM/yyyy', dateOutputFormat: 'dd/MM/yyyy', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { label: 'objet de la formation ', field: 'objet', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        // { label: 'NB heures', field: 'nbHeures', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { label: 'Au titre', field: 'auTitre', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { field: 'actions', label: 'Actions', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' }
      ],
      optionsCheckBox: [
        { text: 'Non', value: false },
        { text: 'Oui', value: true }
      ]
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    }
  },
  created() {
    this.titleWithStyle = `Liste formations:
     NB des formations <span class='font-weight-bolder h4 text-primary'> ( ${this.collaborateur.formation.length} )</span> -
     total des heures <span class='font-weight-bolder h4 text-primary'> ( ${this.collaborateur.nbHrsOfFormationCollaborateur} )</span>`
  },
  methods: {
    displayNbrHeures(nb_minutes){
      let message = '';
      let minutes = 0;
      let heures = 0;
      if(nb_minutes){
        let cast_minutes = nb_minutes / 60;
        heures = Math.trunc(cast_minutes);
        minutes = nb_minutes - (heures * 60);
      }
      if(heures > 0){
        message += heures+'h ';
      }
      if(minutes > 0)
      {
        message += minutes+'min';
      }
      return message;
    },
    messageToast(text, title, variant) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'BellIcon',
          text,
          variant
        }
      })
    },
    downloadDocument(base64, name) {
      if (base64) {
        const a = document.createElement('a')
        a.href = base64
        a.download = name
        a.click()
        this.messageToast('Le document a été téléchargé avec succès.', 'Succès', 'success')
      } else {
        this.messageToast('Aucun document trouvé à télécharger, veuillez essayer de le télécharger après.', 'Erreur', 'warning')
      }
    },
    deleteFormation(id) {
      this.$swal({
        title: 'Etes-vous sûr de vouloir supprimer la formation ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(confirmed => {
        if (confirmed.value) {
          this.$http
            .delete(`/collaborateur/deleteFormationCollaborateur/${id}/${this.courtierId}/${this.collaborateur.idQualification}`)
            .then(res => {
              if (res.data.success) {
                this.$emit('update-formation-list', res.data.data[0], this.collaborateur.idQualification, res.data.message)
              } else {
                this.messageToast(res.data.message, 'Erreur', 'error')
              }
            })
            .catch(error => {
              console.log(error.response)
              this.messageToast(error.response.data.errors[0], 'Erreur', 'error')
            })
        }
      })
    },
    checkDDA(dda){
      let total_minutes = dda / 60;
      return total_minutes;
    },
    clearDocumentDisplay() {
      this.document.base64 = null
      this.document.name = null
      this.document.nameToDownload = null
      this.document.extensionDocument = null
    },
    // eslint-disable-next-line consistent-return
    displayDocument(id, name, NomDuDocument) {
      if (!id) {
        this.messageToast("Aucun document trouvé pour l'affichage ou le téléchargement.", 'Information', 'warning')
        return 0
      }
      this.$http
        .get(`/document/generate_base64_for_document/${id}`)
        .then(res => {
          if (res.data.success) {
            this.clearDocumentDisplay()
            if (res.data.data.isDownload) {
              this.downloadDocument(res.data.data.base64, name)
            } else if (!res.data.data.isDownload && res.data.data.extensionDocument === 'IMAGE') {
              this.document.base64 = res.data.data.base64
              this.document.name = NomDuDocument || name
              this.document.nameToDownload = name
              this.document.extensionDocument = res.data.data.extensionDocument
              this.$refs['modal-display-document-formation'].show()
            } else if (!res.data.data.isDownload && res.data.data.extensionDocument === 'PDF') {
              this.downloadDocument(res.data.data.base64, name)
            }
          } else {
            this.clearDocumentDisplay()
            this.messageToast(res.data.message, 'Erreur', 'error')
          }
        })
        .catch(err => {
          this.clearDocumentDisplay()
          this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
          console.error(err)
        })
    },
    deleteTypeDocument(row){
      // (this.collaborateur.typesStatesUser.find((document)=>(document.idType==typeId))).isExistType=false
      // let typestateuser = this.collaborateur.typesStatesUser.find(item=>item.nameType.trim()===row.TypeDeDocument.trim());
      // if(typestateuser.nameType != "Attestation de formation DDA"){
      //   this.collaborateur.typesStatesUser.find(item=>item.nameType.trim()===row.TypeDeDocument.trim()).isExistType=false
      // }
      this.$emit('delete-document-collaborateur');
    }
  }
}
</script>
<style scoped>
.collaborateur-card::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #00000029;
    z-index: 99;
}
</style>
