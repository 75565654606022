<template>
  <div>
    <!-- Title page -->
    <div class="mx-auto mb-2">
      <b-row class="justify-content-between align-items-center">
        <b-col md="4">
          <h1 class="text-primary font-weight-bolder text-uppercase ml-1 my-0">Gestion des interlocuteurs</h1>
        </b-col>
        <b-col v-if="can('Create user')" class="text-md-right text-sm-center" md="4" offset-md="4">
          <b-button v-b-toggle.sidebar-collaborateur v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary"
                    @click="isUpdate = false"
          >
            <feather-icon class="mr-50" icon="PlusIcon"/>
            <span class="align-middle">Nouvel interlocuteur</span>
          </b-button>
        </b-col>
      </b-row>
    </div>

    <actions-droits-vue v-if="can('management permissions user')" ref="modal-permission-collaborateur"
                        :collaborateur="collaborateurSelectForDroits"
                        :courtier-id="currentUser.courtier_user[0].courtier_id"
                        :user-id="currentUser.courtier_user[0].user_id"
    />

    <actions-document-collaborateur id="collaborateur" :courtier-id="currentUser.courtier_user[0].courtier_id"
                          :user-id="userIdForAddNewDocumentCollaborateur"
                          :documentConformiteAndNonConformite="documentConformiteAndNonConformite"
                          :is-interface-collaborateur="true"
                          @record-document-courtier-returned="recordDocumentCourtierReturned"
    />

    <actions-formation-collaborateur-vue :courtier-id="currentUser.courtier_user[0].courtier_id"
                                         :optionsFamille="documentConformiteAndNonConformite"
                                         :data-select-only-one-formation="dataSelectOnlyOneFormation"
                                         :data-selected-for-edit-formation="dataSelectedForEditFormation"
                                         :is-update-formation="isUpdateFormation"
                                         @update-formation-list="updateFormationList"
    />

    <actions-collaborateur-vue :courtier-id="currentUser.courtier_user[0].courtier_id"
                               :data-selected-for-edit="dataSelectedForEdit"

                               :denomination-commercial="currentUser.courtier_user[0].courtier.personne_morale.denomination_commercial"
                               :is-update="isUpdate"
                               :user-id="currentUser.courtier_user[0].user_id"
                               @record-collaborateur-returned="RecordCollaborateurReturned"
    />

    <b-overlay :show="showLoading" no-wrap/>
    <div v-if="can('List users')">
      <div class="ml-1 mb-2" v-if="!showLoading && calculeUser != 'ilimite' ">
        <h3 style="color: black;" class="font-italic">Vous pouvez saisir {{ calculeUser != 0 ? calculeUser : 0  }} utilisateur(s)</h3> 
      </div>

      <div class="mt-1">
        <collaborateur-card-vue v-for="(collaborateur, index) in rowsCollaborateur" :key="index"
                                :collaborateur="collaborateur"
                                :courtier-id="currentUser.courtier_user[0].courtier_id"
                                :index="index"
                                :user-id="currentUser.courtier_user[0].user_id"
                                :is-principal-current-user="currentUser.courtier_user[0].isPrincipal"
                                @select-user-for-permission="selectUserForPermission"
                                @create-document-collaborateur="createDocumentCollaborateur"
                                @update-formation="updateFormation"
                                @update-formation-list="updateFormationList" @update-collaborateur="updateCollaborateur"
                                @formation-collaborateur="formationCollaborateur"
                                @delete-document-collaborateur="deleteDocumentCollaborateur"
        />

      </div>
      
    </div>
  </div>
</template>

<script>
import { BButton, BCard, BCardHeader, BCardText, BCol, BImg, BMedia, BOverlay, BRow, VBToggle } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import CollaborateurCardVue from './CollaborateurCard.vue'
import ActionsCollaborateurVue from './components-actions-collaborateurs/ActionsCollaborateur.vue'
import ActionsFormationCollaborateurVue
  from './components-actions-collaborateurs/formation-collaborateur/ActionsFormationCollaborateur.vue'
// import ActionsDocumentVue from '../gestion-cabinet/autres-documents/ActionsDocument.vue'
import ActionsDocumentCollaborateur from '../gestion-cabinet/autres-documents/ActionsDocumentCollaborateur.vue'
import ActionsDroitsVue from './components-actions-collaborateurs/droits-collaborateurs/ActionsDroits.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BCardText,
    BCardHeader,
    BMedia,
    BImg,
    BOverlay,

    // UI
    CollaborateurCardVue,
    ActionsCollaborateurVue,
    ActionsFormationCollaborateurVue,
    // ActionsDocumentVue,
    ActionsDocumentCollaborateur,
    ActionsDroitsVue
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  data() {
    return {
      currentUser: JSON.parse(localStorage.getItem('userData')),
      showLoading: false,
      isUpdate: false,
      isUpdateFormation: false,
      rowsCollaborateur: [],
      dataSelectOnlyOneFormation: null,
      collaborateurSelectForDroits: {
        attestationHonorabilite: false,
        curriculumVitae: false,
        idQualification: null,
        justificatifFormation: false,
        niveau: null,
        nomCollaborateur: null,
        prenomCollaborateur: null,
        userId: null
      },
      dataSelectedForEdit: {
        userId: null,
        courtierId: null,
        qualificationId: null
      },
      userIdForAddNewDocumentCollaborateur: null,
      dataSelectedForEditFormation: {
        attestationHonorabilite: false,
        curriculumVitae: false,
        idQualification: null,
        justificatifFormation: false,
        niveau: null,
        nomCollaborateur: null,
        prenomCollaborateur: null,
        userId: null
      },
      documentConformiteAndNonConformite:[]
    }
  },
  computed: {
    calculeUser() {
      let pack = this.currentUser.courtier_user[0].courtier.abonnement.pack_id

      let numbreUserSaisi = this.rowsCollaborateur.filter(collaborateur =>
        collaborateur.isNonUtilisateur == false && collaborateur.isActive == true
      );

      let totalUserPossibleSaisi 
      let totalUserPossible = 0

      switch (pack) {
        case 1:
          totalUserPossible = 5
          totalUserPossibleSaisi = totalUserPossible - numbreUserSaisi.length
          break;
        case 2:
          totalUserPossible = 10
          totalUserPossibleSaisi = totalUserPossible - numbreUserSaisi.length
          break;
        // additional cases as needed
        case 3:
          totalUserPossible = 'ilimite'
          totalUserPossibleSaisi = 'ilimite'
          break;
        default:
        // code to be executed if none of the cases match expression
      }

      return totalUserPossibleSaisi

    },
  },
  created() {
    this.fetchCollaborateurAndQualificationByCourtier()
    this.fetchDocumentConformiteAndNonConformite()
  },
  methods: {
    // emit
    selectUserForPermission(data) {
      this.collaborateurSelectForDroits = data
      this.$refs['modal-permission-collaborateur'].showModal()
    },
    recordDocumentCourtierReturned(data, message) {
      // const indexCollaborateur = this.rowsCollaborateur.findIndex(el => el.userId === this.userIdForAddNewDocumentCollaborateur)
      // this.rowsCollaborateur[indexCollaborateur].documentUser.push(data[0])
      this.fetchCollaborateurAndQualificationByCourtier()
      this.messageToast(message, 'Succès', 'success')
    },
    createDocumentCollaborateur(userId) {
      this.userIdForAddNewDocumentCollaborateur = userId
      this.$root.$emit('bv::toggle::collapse', 'sidebar-document-collaborateur')
    },
    updateCollaborateur(userId, qualificationId) {
      this.dataSelectedForEdit.userId = userId
      this.dataSelectedForEdit.courtierId = this.currentUser.courtier_user[0].courtier_id
      this.dataSelectedForEdit.qualificationId = qualificationId
      this.isUpdate = true
      this.$root.$emit('bv::toggle::collapse', 'sidebar-collaborateur')
    },
    updateFormation(collaborateur, formation) {
      this.dataSelectedForEditFormation = collaborateur
      this.dataSelectOnlyOneFormation = formation
      this.isUpdateFormation = true
      this.$root.$emit('bv::toggle::collapse', 'sidebar-formation-collaborateur')
    },
    formationCollaborateur(collaborateur) {
      this.dataSelectedForEditFormation = collaborateur
      this.isUpdateFormation = false
      this.dataSelectOnlyOneFormation = null
      this.$root.$emit('bv::toggle::collapse', 'sidebar-formation-collaborateur')
    },
    updateFormationList(collaborateur, idQualification, message) {
      const indexCollaborateur = this.rowsCollaborateur.findIndex(el => el.idQualification === idQualification)
      this.rowsCollaborateur.splice(indexCollaborateur, 1)
      this.rowsCollaborateur.unshift(collaborateur)
      this.messageToast(message, 'Succès', 'success')
    },
    deleteDocumentCollaborateur(){
      this.fetchCollaborateurAndQualificationByCourtier()
    },

    // message
    messageToast(text, title, variant) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'BellIcon',
          text,
          variant
        }
      })
    },
    // record
    RecordCollaborateurReturned(collaborateur, message) {
      if (!this.isUpdate) {
        // this.rowsCollaborateur.push(collaborateur)
        this.fetchCollaborateurAndQualificationByCourtier()
        // this.messageToast(message, 'Succès', 'success')
        this.$swal({
          title: 'Succès',
          html: message,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else {
        // const indexCollaborateur = this.rowsCollaborateur.findIndex(el => el.idQualification === collaborateur.idQualification)
        // this.rowsCollaborateur.splice(indexCollaborateur, 1)
        // this.rowsCollaborateur.push(collaborateur)
        this.fetchCollaborateurAndQualificationByCourtier()
        this.isUpdate = false
        this.messageToast(message, 'Succès', 'success')
      }
    },

    // fetch data
    fetchCollaborateurAndQualificationByCourtier() {
      this.showLoading = true
      this.rowsCollaborateur = []
      this.$http
          .get(`/collaborateur/fetchCollaborateurAndQualificationByCourtier/${this.currentUser.courtier_user[0].courtier_id}`)
          .then(res => {
            if (res.data.success) {
              console.log('fetch data now')
              console.log(res.data.data)
              let collaborateurs = res.data.data
              this.rowsCollaborateur = collaborateurs.sort(function(c1, c2) {
                    return (c1.isActive === c2.isActive)? 0 : c1.isActive? -1 : 1;
              });
              this.showLoading = false
            } else {
              this.showLoading = false
              this.messageToast('Nous n\'avons pas pu trouver la ressource que vous avez demandée.', 'Erreur', 'warning')
            }
          })
          .catch(err => {
            this.showLoading = false
            this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
            console.error(err)
          })
    },
    fetchDocumentConformiteAndNonConformite() {
      this.documentConformiteAndNonConformite = []
      this.$http
        .post('/document_nature/fetch_document_nature_with_types')
        .then(res => {
          if (res.data) {
              res.data.forEach(el => {
                this.documentConformiteAndNonConformite.push({
                  value: {
                    id: el.id,
                    typeDocument: el.documentype
                  },
                  text: el.libelle
                })
              })
          } else {
            this.messageToast('Nous n\'avons pas pu trouver la ressource que vous avez demandée.', 'Erreur', 'warning')
          }
        })
        .catch(err => {
          // this.showLoading = false
          this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
          console.error(err)
        })
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
